import { action, observable, makeAutoObservable } from 'mobx'
import ITestimonial from '../interfaces/testimonial/testimonial'
import { TestimonialRequest } from '../requests'


/**
 * @export
 * @class caseStudyStore
 */
export class TestimonialStore {

  constructor() {
    makeAutoObservable(this)
  }
  
  @observable isLoading = false 
  @observable testimonialList: ITestimonial[] = []
  @observable hasNextPage = false
  @observable hasPreviousPage = false
  @observable paging = {
    pageIndex: 0,
    totalCount: 0,
    totalPages: 0
  }

  @action async getTestimonialData(params: any): Promise<void> {
    try {
      const rs = await TestimonialRequest.getAll(params)
      this.testimonialList = rs.data.items
      this.paging = {
        pageIndex: rs.data.pageIndex,
        totalCount: rs.data.totalCount,
        totalPages: rs.data.totalPages
      }
      this.hasNextPage = rs.data.hasNextPage
      this.hasPreviousPage = rs.data.hasPreviousPage
    } catch (error) {
      console.log(error)
    }
  }

  @action async getAllTestimonialData(params: any): Promise<void> {
    try {
      const rs = await TestimonialRequest.getAllTestimonial(params)
      this.testimonialList = rs.data.items
      this.paging = {
        pageIndex: rs.data.pageIndex,
        totalCount: rs.data.totalCount,
        totalPages: rs.data.totalPages
      }
      this.hasNextPage = rs.data.hasNextPage
      this.hasPreviousPage = rs.data.hasPreviousPage
    } catch (error) {
      console.log(error)
    }
  }

  @action clearOnUnmount(): void {
    this.testimonialList = []
    this.paging = {
      pageIndex:0,
      totalCount: 0,
      totalPages: 0
    }
    this.hasNextPage = false
    this.hasPreviousPage = false
  }
  
}

export default new TestimonialStore()
