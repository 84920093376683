import { action, observable, makeAutoObservable } from 'mobx'
import { PostRequest } from '../requests'

type IPostData = {
  items: IPostItem[],
  pageIndex: number,
  totalCount: number,
  totalPages: number
}
export type IPostItem = {
  attachments: any,
  authorId: string,
  authorName: string,
  categoryId: string,
  categoryName: string,
  content: string,
  description: string,
  id: string,
  isPublished: boolean,
  publishedDate: string,
  tags: string,
  title: string,
  updatedDate: string,
}
/**
 * @export
 * @class categoriesStore
 */
export class PostStore {

  constructor() {
    makeAutoObservable(this)
  }
  
  @observable isLoading = false
  @observable postData: IPostData = {
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1
  }
  @observable newsFeed = [] as any
  @observable newsFeedTotalPages = 0
  @observable newsFeedPageIndex = 0
  @observable newsFeedTotalCount = 0
  @observable newsFeedLoading = false
  @observable hasNextPage = false

  @action setLoadingProgress(state: boolean): void {
    this.isLoading = state
  }

  @action async getPostData(params: any): Promise<void> {
    try {
      const rs = await PostRequest.getAll(params)
      this.postData = rs.data
    } catch (error) {
      console.log(error)
    }
  }
  @action async createPost(data: any): Promise<void> {
    try {
      await PostRequest.createPost(data)
    } catch (error) {
      console.log(error)
    }
  }

  @action async getNewsFeed(params: string, isLoadMore?: boolean): Promise<void> {
    try {
      this.newsFeedLoading = true
      const rs = await PostRequest.getNewsFeed(params)
      if (rs) {
        if (isLoadMore) {
          this.newsFeed = [...this.newsFeed, ...rs.data.items]
        } else {
          this.newsFeed = rs.data.items
        }
        this.newsFeedTotalPages = rs.data.totalPages
        this.newsFeedPageIndex = rs.data.pageIndex
        this.newsFeedTotalCount = rs.data.totalCount
        this.newsFeedLoading = false
        this.hasNextPage = rs.data.hasNextPage
      }
    } catch (error) {
      this.newsFeedLoading = false
    }
  }

  @action clearOnUnmount(): void {
    this.newsFeed = []
    this.newsFeedTotalPages = 0
    this.newsFeedPageIndex = 0
    this.newsFeedTotalCount = 0
    this.newsFeedLoading = false
    this.hasNextPage = false
  }
}

export default new PostStore()
