import axios, { AxiosResponse, CancelToken } from 'axios'
import { apiUrl, apiOtrafyUrl } from './config'
import { apiPath } from './constant/url'
import ISignUpContent, { SignUpContentModel, UpdateSignUpContentModel } from './interfaces/signUpContent/signUpContent'
import { ICreateAuthor } from './store/authorStore'

/**
 * @template T
 * @param {AxiosResponse<T>} response
 * @param {boolean} isRawResponse
 * @return {T}
 */
function processResponse<T>(response: AxiosResponse<T>, isRawResponse: boolean): T {
  if (isRawResponse || response.data === undefined) return response as any as T
  return response?.data
}

const requests = {
  get: async <T>(url: string, header = false, requestToken?: CancelToken, isRawResponse = false) => {
    const headers = header
      ? {
        Pragma: 'no-cache',
      }
      : undefined
    const response = await axios.request<T>({
      method: 'get',
      url: `${apiUrl}${url}`,
      cancelToken: requestToken,
      headers: headers,
    })
    return processResponse<T>(response, isRawResponse)
  },

  post: async <T>(url: string, body?: any, header = false, requestToken?: CancelToken) => {
    if (header) {
      const response = await axios.request<T>({
        method: 'post',
        url: `${apiUrl}${url}`,
        cancelToken: requestToken,
        headers: {
        },
        data: body,
      })
      return response?.data
    }
    const response = await axios.request<T>({
      method: 'post',
      url: `${apiUrl}${url}`,
      cancelToken: requestToken,
      data: body,
    })
    return response?.data
  },

  delete: async <T>(url: string, header = false) => {
    if (header) {
      const response = await axios.request<T>({
        method: 'delete',
        url: `${apiUrl}${url}`,
        headers: {
        },
      })
      return response?.data
    }
    const response = await axios.request<T>({
      method: 'delete',
      url: `${apiUrl}${url}`,
    })
    return response?.data
  },

  put: async <T>(url: string, body: any = {}, header = false) => {
    if (header) {
      const response = await axios.request<T>({
        method: 'put',
        url: `${apiUrl}${url}`,
        headers: {
        },
        data: body,
      })
      return response?.data
    }
    if (body) {
      const response = await axios.request<T>({
        method: 'put',
        url: `${apiUrl}${url}`,
        data: body,
      })
      return response?.data
    }
    const response = await axios.request<T>({
      method: 'put',
      url: `${apiUrl}${url}`,
    })
    return response?.data
  },
}

const CategoryRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_GET_CATEGORY}`, data),
  getCategory: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_CATEGORY}?id=${id}`),
  updateCategory: (data: { [key: string]: string }): Promise<any> =>
    requests.put<any>(`${apiPath.API_CATEGORY}`, data),
  deleteCategory: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_CATEGORY}?id=${id}`),
  createCategory: (categoryName: string): Promise<any> =>
    requests.post<any>(`${apiPath.API_CATEGORY}?categoryName=${categoryName}`),
  getCategoryOptions: (): Promise<any> =>
    requests.get<any>(`${apiPath.API_GET_CATEGORY_OPTIONS}`),
}

const AuthorRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_GET_ALL_AUTHOR}`, data),
  getAuthor: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_AUTHOR}?id=${id}`),
  updateAuthor: (data: { [key: string]: string }): Promise<any> =>
    requests.put<any>(`${apiPath.API_AUTHOR}`, data),
  deleteAuthor: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_AUTHOR}?id=${id}`),
  createAuthor: (data: ICreateAuthor): Promise<any> =>
    requests.post<any>(`${apiPath.API_AUTHOR}`, data)
}

const PostRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_GET_ALL_POST}`, data),
  getPost: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_POST}?id=${id}`),
  updatePost: (data: any): Promise<any> =>
    requests.put<any>(`${apiPath.API_POST}`, data),
  deletePost: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_POST}?id=${id}`),
  publishPost: (id: string): Promise<any> =>
    requests.put<any>(`${apiPath.API_POST}/publish/${id}`),
  createPost: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_POST}`, data),
  getNewsFeed: (params: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_GET_NEWS_FEED}${params}`),
}
const SubscriptionRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_GET_SUBSCRIPTION}`, data),
  deleteSubscription: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_SUBSCRIPTION}?id=${id}`),
  createSubscription: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_SUBSCRIPTION}`, data),
}
const ContactRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_GET_CUSTOMER_REQUEST}`, data),
  getAllContactData: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_ALL_CONTACT}`, data),
  deleteContact: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_CONTACT}?id=${id}`),
  createContactRequest: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CONTACT}`, data),
  deleteDemoRequest: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_CUSTOMER_REQUEST}?id=${id}`),
}

const Customer = {
  createCustomerRequest: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CUSTOMER_REQUEST}`, data),
}

const TestimonialRequest = {
  getAll: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_ADMIN_GET_ALL_TESTIMONIAL}`, data),
  getTestimonial: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_TESTIMONIAL}?id=${id}`),
  updateTestimonial: (data: any): Promise<any> =>
    requests.put<any>(`${apiPath.API_TESTIMONIAL}`, data),
  deleteTestimonial: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_TESTIMONIAL}?id=${id}`),
  createTestimonial: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_TESTIMONIAL}`, data),
  getAllTestimonial: (params: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_GET_ALL_TESTIMONIAL}${params}`),
}

const JobRequests = {
  getAllLocationByAdmin: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_LOCATION}/Search`, data),
  createJobLocation: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_LOCATION}`, data),
  deleteJobLocation: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_LOCATION}?id=${id}`),
  getJobLocation: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_LOCATION}?id=${id}`),
  updateJobLocation: (data: {[key: string]: string }): Promise<any> =>
    requests.put<any>(`${apiPath.API_LOCATION}`, data),
}

const DepartmentRequests = {
  getAllDepartmentByAdmin: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_DEPARTMENT}/Search`, data),
  createDepartment: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_DEPARTMENT}`, data),
  deleteDepartment: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_DEPARTMENT}?id=${id}`),
  getDepartment: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_DEPARTMENT}?id=${id}`),
  updateDepartment: (data: {[key: string]: string }): Promise<any> =>
    requests.put<any>(`${apiPath.API_DEPARTMENT}`, data),
}

const JobTypeRequests = {
  getAllJobTypeByAdmin: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_JOBTYPE}/Search`, data),
  createJobType: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_JOBTYPE}`, data),
  deleteJobType: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_JOBTYPE}?id=${id}`),
  getJobType: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_JOBTYPE}?id=${id}`),
  updateJobType: (data: {[key: string]: string }): Promise<any> =>
    requests.put<any>(`${apiPath.API_JOBTYPE}`, data),
}

const CarrerRequests = {
  getAllCarrerByAdmin: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CAREER}/Search`, data),
  createCarrer: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CAREER}`, data),
  deleteCarrer: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_CAREER}?id=${id}`),
  getCarrer: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_CAREER}?id=${id}`),
  updateCarrer: (data: any): Promise<any> =>
    requests.put<any>(`${apiPath.API_CAREER}`, data),
  getAllCarrers: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CAREER}/GetAll`, data),
}

const CandidateRequests = {
  getAllCandidateByAdmin: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CANDIDATE}/Search`, data),
  createCandidate: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CANDIDATE}`, data),
  deleteCandidate: (id: string): Promise<any> =>
    requests.delete<any>(`${apiPath.API_CANDIDATE}?id=${id}`),
  getCandidate: (id: string): Promise<any> =>
    requests.get<any>(`${apiPath.API_CANDIDATE}?id=${id}`),
  updateCandidate: (data: any): Promise<any> =>
    requests.put<any>(`${apiPath.API_CANDIDATE}`, data),
  getAllCandidate: (data: any): Promise<any> =>
    requests.post<any>(`${apiPath.API_CANDIDATE}/GetAll`, data),
}

const SignUpContentRequest = {
  getSignUpContent: (): Promise<any> =>
    requests.get<any>(`${apiPath.API_SIGN_UP_CONTENT}`),
  updateSignUpContent: (data: UpdateSignUpContentModel): Promise<any> =>
    requests.put<number>(`${apiPath.API_SIGN_UP_CONTENT}`, data),
  deleteSignUpContent: (id: string): Promise<any> =>
    requests.delete<number>(`${apiPath.API_SIGN_UP_CONTENT}?id=${id}`),
  createSignUpContent: (data: SignUpContentModel): Promise<any> =>
    requests.post<number>(`${apiPath.API_SIGN_UP_CONTENT}`, data),
  getSubscriptionPlan: (): Promise<any> =>
    requests.get<any>(`${apiOtrafyUrl+apiPath.API_SUBSCRIPTION_PLAN}`),
}

export {
  PostRequest,
  CategoryRequest,
  AuthorRequest,
  Customer,
  SubscriptionRequest,
  ContactRequest,
  TestimonialRequest,
  JobRequests,
  DepartmentRequests,
  JobTypeRequests,
  CarrerRequests,
  CandidateRequests,
  SignUpContentRequest,
}
