import { observable, makeAutoObservable } from "mobx"

export type IToastData = {
  message: string,
  type: string
}
const TOAST_TIME = 3000;
/**
 * @export
 * @class ToastStore
 */
export class ToastStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable message = ''
  @observable type = ''

  setToast(data: IToastData): void {
    const { message, type } = data;
    this.message = message;
    this.type = type;
    setTimeout(() => {
      this.deleteToast();
    }, TOAST_TIME);
  }

  deleteToast(): void {
    this.message = '';
    this.type = '';
  }
}

const toastStore = new ToastStore();
export default toastStore
