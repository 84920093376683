/* eslint-disable require-jsdoc */


import axios from 'axios'
import { action, observable } from 'mobx'

export class Localization {

  @observable localization = {} as { [key: string]: any }

  @action async getLocalizationData(): Promise<void> {
    let response: any = null

    while (response === null) {
      response = await axios.request({
        method: 'get',
        url: `https://application.otrafy.com/api/account/GetLocalizationData`,
      })
    }
    this.localization = response.data.data
  }

  getTranslation(language: any, keys: string[]): string | undefined {
    const value = keys.reduce((obj, key, index) => {
      if (index === 0 && key === 'common') return obj
      return obj ? obj[key] : obj
    }, language)
    return value
  }

  toCamelCase(text: string): string {
    return text.replace(/_\w/g, s => s[1].toUpperCase())
  }

  @action useTranslation(): { t: (key: string) => string } {
    const t = (key: string): string => {
      const keys = key.split('.').map(k => (k.includes('_') ? this.toCamelCase(k) : k))
      const language = 'English'
      let value = this.getTranslation(this.localization['English'], keys)
      if (value && value !== '') return value
      if (language != 'English') value = this.getTranslation(this.localization.English, keys)
      return value || ''
    }
    return { t: t }
  }

}
const localization = new Localization()
export default localization

export function useTranslation(): { t: (key: string) => string } {
  return localization.useTranslation()
}