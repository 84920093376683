import { action, observable, makeAutoObservable } from 'mobx'
import { SubscriptionRequest } from '../requests'

type ISubscriptionData = {
  items: ISubscriptionItem[],
  pageIndex: number,
  totalCount: number,
  totalPages: number
}
export type ISubscriptionItem = {
  id: string,
  email: string,
  updatedDate: string
}
/**
 * @export
 * @class subscriptionStore
 */
export class SubscriptionStore {

  constructor() {
    makeAutoObservable(this)
  }

  @observable isLoading = false
  @observable subscriptionData: ISubscriptionData = {
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1
  }
  @observable subscriptionList = [] as any

  @action setLoadingProgress(state: boolean): void {
    this.isLoading = state
  }

  @action async getsubscriptionData(params: any): Promise<void> {
    try {
      this.setLoadingProgress(true)
      const rs = await SubscriptionRequest.getAll(params)
      this.subscriptionData = rs.data
      this.setLoadingProgress(false)
    } catch (error) {
      console.log(error)
    }
  }
  @action async createSubscription(name: string): Promise<void> {
    try {
      await SubscriptionRequest.createSubscription(name)
    } catch (error) {
      console.log(error)
    }
  }

  @action clearOnUnmount(): void {
    this.subscriptionList = []
  }
}

export default new SubscriptionStore()
