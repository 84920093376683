import { action, observable, makeAutoObservable } from 'mobx'
import { AuthorRequest } from '../requests'

type IAuthorData = {
  items: IAuthorItem[],
  pageIndex: number,
  totalCount: number,
  totalPages: number
}
export type IAuthorItem = {
  id: string,
  name: string,
  updatedDate: string,
  avatar: string,
  jobTitle: string;
}
export interface ICreateAuthor {
  firstName: string,
  lastName: string,
  email: string,
  jobTitle: string,
  avatar: string
}
/**
 * @export
 * @class categoriesStore
 */
export class AuthorStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable isLoading = false
  @observable authorData: IAuthorData = {
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1
  }

  @action setLoadingProgress(state: boolean): void {
    this.isLoading = state
  }

  @action async getauthorData(params: any): Promise<void> {
    try {
      this.setLoadingProgress(true);
      const rs = await AuthorRequest.getAll(params)
      this.authorData = rs.data
      this.setLoadingProgress(false);
    } catch (error) {
      console.log(error)
    }
  }
  @action async createAuthor(data: ICreateAuthor): Promise<void> {
    try {
      await AuthorRequest.createAuthor(data)
    } catch (error) {
      console.log(error)
    }
  }
}

export default new AuthorStore()
