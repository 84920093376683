import { action, observable, makeAutoObservable } from 'mobx'
import { CategoryRequest } from '../requests'

type ICategoryData = {
  items: ICategoryItem[],
  pageIndex: number,
  totalCount: number,
  totalPages: number
}
export type ICategoryItem = {
  id: string,
  name: string,
  updatedDate: string
}
/**
 * @export
 * @class categoriesStore
 */
export class CategoriesStore {

  constructor() {
    makeAutoObservable(this)
  }

  @observable isLoading = false
  @observable categoryData: ICategoryData = {
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1
  }
  @observable categoryList = [] as any

  @action setLoadingProgress(state: boolean): void {
    this.isLoading = state
  }

  @action async getcategoryData(params: any): Promise<void> {
    try {
      this.setLoadingProgress(true)
      const rs = await CategoryRequest.getAll(params)
      this.categoryData = rs.data
      this.setLoadingProgress(false)
    } catch (error) {
      console.log(error)
    }
  }
  @action async createCategory(name: string): Promise<void> {
    try {
      await CategoryRequest.createCategory(name)
    } catch (error) {
      console.log(error)
    }
  }

  @action async getAllCategory(): Promise<void> {
    try {
      const rs = await CategoryRequest.getCategoryOptions()
      if (rs.isSuccess) this.categoryList = rs.data
    } catch (error) {
      console.log(error)
    }
  }

  @action clearOnUnmount(): void {
    this.categoryList = []
  }
}

export default new CategoriesStore()
