import { action, observable, makeAutoObservable } from 'mobx'
import ISignUpContent from '../interfaces/signUpContent/signUpContent'
import { SignUpContentRequest } from '../requests'

/**
 * @export
 * @class signUpContentStore
 */
export class SignUpContentStore {
  /**
   * constructor.
   * @memberof SignUpContentStore
   */
  constructor() {
    makeAutoObservable(this)
  }

  @observable isLoading = false
  @observable signUpContent: ISignUpContent = {}
  @observable signUpContentList: ISignUpContent[] = []

  /**
   * Get sign up content data.
   * @memberof SignUpContentStore
   */
  @action async getSignUpContentData(): Promise<void> {
    try {
      this.isLoading = true
      this.signUpContentList = []
      const rs = await SignUpContentRequest.getSignUpContent()
      this.signUpContent = rs.data
      if (this.signUpContent) {
        this.signUpContentList = [this.signUpContent]
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * ClearOnUnmount.
   * @memberof SignUpContentStore
   */
  @action clearOnUnmount(): void {
    this.signUpContent = {}
  }
}

export default new SignUpContentStore()
