import { action, observable, makeAutoObservable } from 'mobx'
import { ContactRequest } from '../requests'

type IContactData = {
  items: IContactItem[],
  pageIndex: number,
  totalCount: number,
  totalPages: number
}
export type IContactItem = {
  id: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  message: string,
  updatedDate: string,
  fullName: string,
  jobTitle: string,
  howWeHelp: string
  company: string
}
/**
 * @export
 * @class contactStore
 */
export class ContactStore {

  constructor() {
    makeAutoObservable(this)
  }

  @observable isLoading = false
  @observable contactData: IContactData = {
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1
  }
  @observable contactList = [] as any

  @action setLoadingProgress(state: boolean): void {
    this.isLoading = state
  }

  @action async getContactData(params: any): Promise<void> {
    try {
      this.setLoadingProgress(true)
      const rs = await ContactRequest.getAllContactData(params)
      this.contactData = rs.data
      this.setLoadingProgress(false)
    } catch (error) {
      console.log(error)
    }
  }

  @action async getDemoData(params: any): Promise<void> {
    try {
      this.setLoadingProgress(true)
      const rs = await ContactRequest.getAll(params)
      this.contactData = rs.data
      this.setLoadingProgress(false)
    } catch (error) {
      console.log(error)
    }
  }

  @action clearOnUnmount(): void {
    this.contactList = []
  }
}

export default new ContactStore()
